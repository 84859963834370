import mgnUa from 'mgn-ua';

const UA = new mgnUa();

export default () => {

	// ----------------------------------------------------------
	// SNS Share
	// ----------------------------------------------------------

	const TARGET = document.querySelectorAll('.j-share [data-sns]');

	for (var i = 0; i < TARGET.length; i++) {

		TARGET[i].addEventListener( 'click', (e) => {

			let dataType = e.currentTarget.getAttribute("data-sns"),
				shareURL,
				shareType,
				shareOpt;

			//表示中のURLをシェア
			let siteURL = encodeURIComponent(location.href);

			//指定URLをシェア
			// let dataURL = TARGET[i].parents('.j-share').attr('data-url'),
			// if( dataURL ){
			// 	if( dataURL.indexOf('http') > -1 ){
			// 		siteURL = encodeURIComponent( dataURL );
			// 	}else{
			// 		siteURL = encodeURIComponent( location.origin + dataURL);
			// 	}
			// }

			if( dataType == "fb" ) { //FB

				shareURL = 'https://www.facebook.com/sharer/sharer.php?u='+siteURL;
				shareOpt = 'scrollbars=yes,location=yes,toolbar=no,width=670,height=340';

			} else if( dataType == "tw" ) { //TW

				shareURL = 'https://twitter.com/intent/tweet?url='+siteURL+'&text='+encodeURIComponent(document.title);
				shareOpt = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=550,height=420';

			} else if( dataType == "line" ) { //Line

				if( UA.isSp || UA.isTab ){
					// アプリ版
					shareURL = 'http://line.me/R/msg/text/?'+siteURL;
				}else{
					// Web版
					shareURL = 'https://timeline.line.me/social-plugin/share?url='+siteURL;
				}
				shareOpt = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=500,height=550';
			}

			window.open( shareURL, null, shareOpt );

		} )
	}
}
