
import { AddClass, RemoveClass, GetParents } from 'mgn-utility';
import mgnUa from 'mgn-ua';
import mgnTab from 'mgn-tab';
import mgnMenu from 'mgn-menu';
import mgnAccordion from 'mgn-accordion';
import mgnSmoothScroll from 'mgn-smooth-scroll';
import mgnScrollEffect from "mgn-scroll-effect"

import ShareSns from './_share';
import ShowPagetop from './_show_pagetop';
import HoverTouch from './_hover_touch';
import SetTransition from './_set_transition';
import HeaderHorizontal from './_header_horizontal';
import Slider from './_slider';
import NaviActive from './_nav_active';
// import MenuBar from './_menu_bar';
import TargetBlank from './_target_blank';

const Init =  () => {

    // ----------------------------------------------------------
    // UA
    // ----------------------------------------------------------

    const UA = new mgnUa();

    if( UA.isIE ) {
        AddClass( document.getElementsByTagName('html')[0], 'is-ie' );
    }
    if( UA.isFirefox ) {
        AddClass( document.getElementsByTagName('html')[0], 'is-ff' );
    }

    // ----------------------------------------------------------
    // ScrollNav
    // ----------------------------------------------------------

    if( document.querySelector('.j-scroll-effect') ){
        new mgnScrollEffect( ".j-scroll-effect", {
            ajustVal: 0
        });
    }

    // ----------------------------------------------------------
    // mgnTab
    // ----------------------------------------------------------

    // let tab_rank = new mgnTab(
    //     '.j-tab_rank',
    //     {
    //         fadeSpeed: 600
    //     }
    // );
    //
    // // tab_rank.Open( ".is-open" );
    //
    // tab_rank.OpenEnd = function(){
    // };


    // ----------------------------------------------------------
    // TargetBlank
    // ----------------------------------------------------------

    TargetBlank();

    // ----------------------------------------------------------
    // MenuBar
    // ----------------------------------------------------------

    // MenuBar();

    // ----------------------------------------------------------
    // Navi Active
    // ----------------------------------------------------------

    NaviActive();

    // ----------------------------------------------------------
    // Slider
    // ----------------------------------------------------------

    Slider();


    // ----------------------------------------------------------
    // HeaderHorizontal
    // ----------------------------------------------------------
    HeaderHorizontal();

    // ----------------------------------------------------------
    // HoverTouch
    // ----------------------------------------------------------

    HoverTouch();


    // ----------------------------------------------------------
    // SetTransition
    // ----------------------------------------------------------

    SetTransition();


    // ----------------------------------------------------------
    // ShowPagetop
    // ----------------------------------------------------------

    ShowPagetop();


    // ----------------------------------------------------------
    // Share
    // ----------------------------------------------------------

    ShareSns();


    // ----------------------------------------------------------
    // Menu - Gnav
    // ----------------------------------------------------------

    let delay = 200,
        bodyYOffset = 0,
        openFlag = false;

        const FuncOpenEnd = function() {
            AddClass( document.querySelector('html'), 'is-open_overlay');
            setTimeout( () => {
                AddClass( document.querySelector('html'), 'is-open_inner');
            },delay )
            window.scrollTo( 0, 0 ); //スクロールコンテンツをTOPに移動

            openFlag = true;
        }

        const FuncCloseEnd = function() {
            RemoveClass( document.querySelector('html'), 'is-open_overlay');
            setTimeout( () => {
                RemoveClass( document.querySelector('html'), 'is-open_inner');
            },delay )
            // console.log(bodyYOffset);
            window.scrollTo( 0, bodyYOffset );

            openFlag = false;
        }

        const ToggleFlag = function() {
            // 開く直前
            if( !openFlag ){
                bodyYOffset = window.pageYOffset;
            }
        }

    const J_MENU = new mgnMenu(
        ".j-menu",
        {
            globalNav: ".j-menu_gnav",
            activeName: "is-open",
            noScroll: false
        }
    );

    J_MENU.OpenEnd = function() {
        AddClass( document.querySelector('html'), 'is-open_gnav');
        FuncOpenEnd();
    }

    J_MENU.CloseEnd = function() {
        RemoveClass( document.querySelector('html'), 'is-open_gnav');
        FuncCloseEnd();
    }

    document.querySelector('.j-menu').addEventListener('click', () => {

        // 開く直前
        ToggleFlag();

    })


    // ----------------------------------------------------------
    // Menu - Search
    // ----------------------------------------------------------

    const J_MENU_SEARCH = new mgnMenu(
        ".j-menu_search",
        {
            globalNav: ".j-menu_search_wrap",
            activeName: "is-open",
            noScroll: false
        }
    );

    J_MENU_SEARCH.OpenEnd = function() {
        AddClass( document.querySelector('html'), 'is-open_search');
        FuncOpenEnd();
    }
    J_MENU_SEARCH.CloseEnd = function() {
        RemoveClass( document.querySelector('html'), 'is-open_search');
        FuncCloseEnd();

    }

    // 検索用ハンバーガーアイコンの装飾を変更
    document.querySelector('.header-searchbtn').addEventListener('click', () => {
        AddClass( document.querySelector('.header-menubtn_search'), 'is-open');
        // 開く直前
        ToggleFlag();
    })
    document.querySelector('.header-menubtn_search').addEventListener('click', () => {

        J_MENU_SEARCH.Close();
        RemoveClass( document.querySelector('.header-menubtn_search'), 'is-open');

    })


    // ----------------------------------------------------------
    // Tab
    // ----------------------------------------------------------

    let tabRank = new mgnTab(
        '.j-tab_rank',
        {
            fadeSpeed: 1000
        }
    );

    tabRank.Open( ".is-default" );

    tabRank.OpenEnd = function(){
        // console.log("OpenEnd");
    };


    // ----------------------------------------------------------
    // Accordion
    // ----------------------------------------------------------


    let J_ACCORDION = new mgnAccordion(
        ".j-accordion",
        {
            toggleSpeed: 200,
            btnElm: ".j-accordion_ttl",
            detailElm: ".j-accordion_detail"
        }
    );



    // ----------------------------------------------------------
    // SmoothScroll
    // ----------------------------------------------------------

    const J_SCROLL = new mgnSmoothScroll(
        "a",
        {
            speed: 1000,
            easing: "easeOutQuint",
            ignore: ".ignore"
        }
    );
}

export default Init;
