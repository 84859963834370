import Swiper from 'swiper';

export default () => {

    const BREAKPOINT = 767;

    // ----------------------------------------------------------
    // Slider
    // ----------------------------------------------------------

    // メインビジュアルなど
    // ----------------------------------------------------------

    const Visual = new Swiper ('#visual .swiper-container', {

        // Optional parameters
        loop: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },

        // effect: 'fade',
        speed: 1500,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })


    // バナー
    // ----------------------------------------------------------

    // const Banner = '.m-bnr .swiper-container';
    //
    // let timer = null,
    // delay = 300,
    // pcFlag;
    //
    // let SliderComponent = new Swiper ( Banner, {
    //
    //     // Optional parameters
    //     slidesPerView: 1,
    //     loop: true,
    //     autoplay: {
    //         delay: 2500,
    //         disableOnInteraction: false
    //     },
    //     speed: 1000,
    //
    //     // Navigation arrows
    //     navigation: {
    //         nextEl: '.swiper-button-next',
    //         prevEl: '.swiper-button-prev',
    //     },
    //
    //     // If we need pagination
    //     pagination: {
    //         el: '.swiper-pagination',
    //         clickable: true,
    //     },
    //
    //     // Responsive breakpoints
    //     breakpoints: {
    //         750: {
    //             slidesPerView: 1
    //         }
    //     }
    // })
    //
    // const DestroySlider = () => {
    //     SliderComponent.destroy( true, true );
    // }
    //
    // if ( window.innerWidth > BREAKPOINT ) {
    //     // 解除
    //     DestroySlider();
    //     pcFlag = true;
    // } else  {
    //     pcFlag = false;
    // }
    //
    //
    // window.addEventListener( 'resize', () => {
    //
    //     clearTimeout( timer );
    //
    //     timer = setTimeout( () => {
    //
    //         ///
    //
    //         if ( window.innerWidth > BREAKPOINT  ) {
    //             if( !pcFlag ){
    //
    //                 // 解除
    //                 DestroySlider();
    //
    //                 pcFlag = true;
    //             }
    //
    //         } else  {
    //             if( pcFlag ){
    //
    //                 // パラメータを保存
    //                 var prm = SliderComponent.params;
    //
    //                 // 再設定
    //                 SliderComponent = new Swiper (Banner, prm );
    //
    //                 pcFlag = false;
    //
    //             }
    //         }
    //
    //         ///
    //
    //     }, delay );
    // } );



    // 最新記事
    // ----------------------------------------------------------

    let Related = new Swiper ('.m-list_related .swiper-container', {

        // Optional parameters
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
        allowTouchMove: false,
        autoHeight: true,
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
        loop: true,
        speed: 2000,

        // Responsive breakpoints
        breakpoints: {
            767: {
                spaceBetween: 0,
                allowTouchMove: true,
                slidesPerView: 2,
                slidesPerGroup: 2,
            }
        },

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    })


    let timer = null,
    delay = 150,
    spFlag;

    const JudgeWindowWidth = ()=> {

        clearTimeout( timer );

        timer = setTimeout( () => {


            // パラメータを保存
            var prm = Related.params;

            ///

            if ( window.innerWidth > BREAKPOINT ) {

                if( spFlag || spFlag == undefined ){

                    //PC処理

                    //再設定
                    // Related.destroy( true, true );
                    // Related = new Swiper ('.m-list_related .swiper-container', prm );

                    //自動再生 STOP
                    Related.autoplay.stop();

                    spFlag = false;

                }
            } else {

                if( !spFlag || spFlag == undefined ){

                    //SP処理

                    //再設定
                    // Related.destroy( true, true );
                    // Related = new Swiper ('.m-list_related .swiper-container', prm );

                    //自動再生 START
                    Related.autoplay.start();

                    spFlag = true;
                }
            }

            ///

        }, delay );
    }

    if( document.querySelector('.m-list_related .swiper-container') ){


        window.addEventListener( 'resize', () => {

            JudgeWindowWidth();

        } );

        window.addEventListener( 'load', () => {

            JudgeWindowWidth();

        } );

    }

}
