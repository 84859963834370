import { AddClass, RemoveClass, GetParents } from 'mgn-utility';

export default () => {

	// ----------------------------------------------------------
	// TargetBlank
	// ----------------------------------------------------------

	const TARGET = document.getElementById('postArea');

	if( TARGET ){

		let link = TARGET.getElementsByTagName('a');

		for (var i = 0; i < link.length; i++) {

			let ElmHref = link[i].getAttribute('href');

			if( ElmHref ){

				// httpが含まれている場合
				if( ElmHref.indexOf('http') > -1 ){

					//外部リンクのみ
					if( ElmHref.indexOf('glam.jp') == -1 && ElmHref.indexOf('tend.jp') == -1 ){
						// target属性が含まれていない場合
						if( !link[i].getAttribute('target') ){
							link[i].setAttribute('target', '_blank');
						}
					}
					
				}
			}
		}

	}



}
