import { AddClass, RemoveClass, GetParents } from 'mgn-utility';

export default () => {

    const TARGET = document.querySelectorAll('.j-navi_active');
    const CLASSNAME = 'is-active';

	// ----------------------------------------------------------
    // URLとNaviアクティブ 連動
    // ----------------------------------------------------------

    const CheckMatchFunc = ( path ) => {

        let noMatchCount = 0;
        let targetLinkHref;

        for ( let j = 0; j < TARGET.length; j++ ) {

            const TARGET_LINK = TARGET[j].querySelectorAll('a');

            // console.log(TARGET_LINK);

            for (let i = 0; i < TARGET_LINK.length; i++) {

                if( TARGET_LINK[i].attributes.href ){
                    targetLinkHref = TARGET_LINK[i].attributes.href.value;
                }

                if( targetLinkHref ){
                    if( targetLinkHref.indexOf('?') > -1 ){

                        targetLinkHref= targetLinkHref.split('?')[0];

                    }
                }

                if( targetLinkHref == path ){

                    AddClass( TARGET_LINK[i], CLASSNAME);

                    // Parent Elm
                    // let parent = GetParents( TARGET_LINK[i], ".j-navi_active_parent" );
                    //
                    // AddClass( parent, CLASSNAME);
                    //
                    //
                    // // Header Clone Nav Current
                    // let parentParentClass = parent.parentNode.attributes.class;
                    // let parentList = document.querySelectorAll('.header-nav .header-parent > li');
                    //
                    // if( parentParentClass ){
                    //     if( parentParentClass.value.indexOf('header-parent') > -1 ){
                    //
                    //         let index = Array.prototype.indexOf.call( parentList , parent);
                    //
                    //         let HeaderCloneList = document.querySelectorAll('.header-bar .header-parent > li');
                    //
                    //         AddClass( HeaderCloneList[ index ], CLASSNAME);
                    //
                    //
                    //     }
                    // }

                    //サイドナビ内かつ親要素にアコーディオンボタンがある場合
                    // if( TARGET[j].attributes.class ){
                    //
                    //     if( TARGET[j].attributes.class.value.indexOf('side-category') > -1 ){
                    //
                    //         let parentSide = GetParents( TARGET_LINK[i], ".j-navi_active_parent" );
                    //
                    //         for (var k = 0; k < parentSide.children.length; k++) {
                    //
                    //             let parentSideBtn = parentSide.children[k];
                    //
                    //             if( parentSideBtn.attributes.class ){
                    //                 if( parentSideBtn.attributes.class.value.indexOf('j-accordion_btn') > -1 ){
                    //                     AddClass( parentSideBtn, 'open-default');
                    //                 }
                    //             }
                    //         }
                    //
                    //     }
                    // }


                }else{
                    noMatchCount++;
                }
            }

            // 全て一致しない場合
            if( noMatchCount == TARGET.length ){

                CHANGE_FORMAT();

            }
        }

    }

    ///

    const CHANGE_FORMAT = () => {

        // 拡張子が含まれる場合はディレクトリ名のみに変換
        if( href.indexOf('.') > -1 ){

            let dir = href.replace(/\\/g, '/').replace(/\/[^/]*$/, '');
            if (dir.match(/^[^/]*\.[^/\.]*$/)) {
                dir = '';
            }

            href = dir + '/';

        }else{

            // 1階層上のディレクトリに変換
            let dir = href.split('/');
            let dirLast = dir[dir.length -2] + '/';

            href = href.replace( dirLast, '');
        }

        // 再チェック
        if( href != '/' ){
            CheckMatchFunc(href);
        }
    }

    ///

    let pathname = location.pathname;
    let href  = pathname;

    // 「index.」が含まれる場合は「/」終わりになるようファイル名を除外
    if( pathname.indexOf('.') > -1 ){
        let filename = pathname.split('/')[pathname.split('/').length-1];
        if( filename.indexOf('index.') > -1 ){
            href = pathname.replace( filename, '');
        }
    }

    CheckMatchFunc(href);


}
